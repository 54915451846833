<template>
  <b-modal
    v-model="showModal"
    centered
    title="Select range to invest"
    @hidden="resetModal"
    @ok="handleInvest"
    ok-title="Invest"
    cancel-title="Close"
  >
    <b-card-text v-if="item">
      <div class="w-100">
        <label for="range-1" class="d-block">Select range</label>
        <b-form-input
          id="range-1"
          v-model="value"
          type="range"
          :min="min"
          step="0.1"
          :max="item.total_amt"
        ></b-form-input>
        <div class="d-flex justify-content-between font-weight-bold">
          <span>{{ min }}</span>
          <span>{{ item.total_amt }}</span>
        </div>
        <div class="mt-2">
          Value: <span class="font-weight-bold">{{ value }}</span>
        </div>
      </div>
    </b-card-text>
    <loader :isLoading="isLoading" />
  </b-modal>
</template>

<script>
import { BModal, BCardText, BFormInput } from "bootstrap-vue";
import Loader from "./Loader.vue";
import { mapState } from "vuex";
import { coinsMinValues } from "@/utils/constants";
export default {
  props: ["isShow", "item"],
  computed: {
    ...mapState(["apiSuccess", "errorMessage"]),
  },
  watch: {
    isShow: function (newVal) {
      this.showModal = newVal;
    },
    item() {
      let coin = this.coinsMinValues.filter((n) => n[this.item.symbol]);
      if (coin.length > 0) {
        this.min = coin[0][this.item.symbol];
        this.value = this.min;
      } else {
        this.min = 0;
      }
      console.log(this.min);
      // this.co;
    },
  },
  data() {
    return {
      showModal: null,
      coinsMinValues,
      value: 0,
      min: 0,
      isLoading: false,
    };
  },
  components: {
    // BCardCode,
    // BButton,
    BModal,
    BCardText,
    BFormInput,
    Loader,
    // BAlert,
  },
  methods: {
    resetModal() {
      this.value = 0;
      this.$emit("closeModal", false);
    },
    async handleInvest(bvModalEvent) {
      bvModalEvent.preventDefault();
      console.log("enter");
      let payload = {
        amount: parseFloat(this.value),
        plan: {
          source: this.item.source.id,
          int_rate: this.item.int_rate,
          amount_available: this.item.amount_available,
          duration: this.item.duration,
          symbol: this.item.symbol,
        },
      };
      this.isLoading = true;
      await this.$store.dispatch("createHedgeFund", payload);
      this.isLoading = false;
      if (this.apiSuccess && this.errorMessage == "") {
        this.$toasted.success("Invested Successfully !!", {
          position: "top-right",
          duration: 2500,
        });
        setTimeout(async () => {
          this.resetModal();
          await this.$store.dispatch("fetchSourceList", {
            source: this.$route.params.source,
            isUpdate: false,
          });
        }, 2500);
      }
    },
  },
};
</script>

<style></style>
