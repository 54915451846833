<template>
  <div class="container mt-4 mb-4">
    <!-- {{ path }} -->
    <div class="row">
      <div class="col-lg-3 col-md-5">
        <h6>Filter</h6>
        <div class="form-group">
          <v-select v-model="selected" :options="option" placeholder="Select" />
        </div>
      </div>
    </div>
    <div class="card shadow table-responsive">
      <table v-if="filteredList" class="table">
        <thead>
          <tr>
            <th scope="col" v-for="(item, i) in fields" :key="i">
              {{ item.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="filteredList && filteredList.length == 0">
            <td colspan="7" class="text-center">No record found</td>
          </tr>
          <tr v-else v-for="(item, i) in filteredList" :key="i">
            <th>{{ item.symbol || "-" }}</th>
            <td>{{ item.total_amt || "-" }}</td>
            <td>{{ item.amount_available || "-" }}</td>
            <td>{{ item.returnable_amount || "-" }}</td>
            <td>{{ item.pnl_per || "-" }}</td>
            <td>{{ item.int_rate || "-" }}</td>
            <td>{{ item.duration || "-" }}</td>
            <td>
              <b-button variant="outline-primary" @click="handleClick(item)">
                Invest Now
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <modal :isShow="isShow" :item="modalItem" @closeModal="handleClose" />
    <loader :isLoading="isLoading" />
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";
import { mapState } from "vuex";
import Loader from "./Loader.vue";
import Modal from "./Modal.vue";
export default {
  //   props: ["fields", "data"],
  computed: {
    ...mapState(["planList", "sourceWallet"]),
    filteredList() {
      // return this.planList;
      return this.planList && this.planList.length > 0
        ? this.planList
            .filter((n) => {
              return n.symbol
                ? n.symbol
                    .toLowerCase()
                    .includes(this.selected ? this.selected.toLowerCase() : "")
                : n;
            })
            .filter((m) => m.symbol)
        : this.planList;
    },
  },
  components: {
    BButton,
    Loader,
    Modal,
  },
  data() {
    return {
      path: "",
      fields: [
        { label: "SYMBOL" },
        { label: "TOTAL AMOUNT" },
        { label: "AVAILABLE AMOUNT" },
        { label: "RETURNABLE AMOUNT" },
        { label: "PNL" },
        { label: "Interest Rate" },
        { label: "Duration" },
      ],
      isLoading: false,
      selected: "",
      isShow: false,
      option: [],
      modalItem: null,
    };
  },
  watch: {
    "$route.path"() {
      this.path = this.$route.path;
    },
    async path() {
      if (this.path.includes("available")) {
        this.isLoading = true;
        await this.$store.dispatch("fetchSourceList", {
          source: this.$route.params.source,
          isUpdate: true,
        });
        this.isLoading = false;
        this.planList.map((n) => {
          if (!this.option.includes(n.symbol)) {
            if (n.symbol) this.option.push(n.symbol);
          }
        });
      }
      if (this.path.includes("running")) {
        this.isLoading = true;
        await this.$store.dispatch("fetchRunningInvestment");
        this.isLoading = false;
      }
      if (this.path.includes("matured")) {
        this.isLoading = true;
        await this.$store.dispatch("fetchAllPlans");
        this.isLoading = false;
        if (this.planList && this.planList && this.planList.length > 0) {
          this.$store.commit(
            "SET_PLAN_LIST",
            this.planList.filter((n) => n.status == "processed")
          );
        }
      }
    },
  },
  methods: {
    handleClick(item) {
      this.modalItem = item;
      this.isShow = true;
    },
    handleClose(payload) {
      this.isShow = payload;
    },
  },
  async mounted() {
    this.path = this.$route.path;
    if (this.path.includes("running") || this.path.includes("matured")) {
      this.isLoading = true;
      await this.$store.dispatch("fetchSourceList", {
        source: this.$route.params.source,
        isUpdate: false,
      });
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss">
.font-small-2 {
  font-size: 13px;
}
div {
  white-space: nowrap;
}
</style>
